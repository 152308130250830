import { FC } from 'react';
import { useThemeContext } from 'providers/ThemeProvider';
import { Box } from '@mui/material';
import LoadingOverlay from 'components/loading/LoadingOverlay';

const LoadingPage: FC = () => {
  const {
    createSxStyles,
    themeConstants: { maxContentWidth },
  } = useThemeContext();
  const { container } = createSxStyles({
    container: {
      position: 'relative',
      width: maxContentWidth,
      height: '100vh',
    },
  });

  return (
    <Box sx={container}>
      <LoadingOverlay />
    </Box>
  );
};

export default LoadingPage;
