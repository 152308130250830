import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectOnMountProps {
  to?: string;
}

const RedirectOnMount: FC<RedirectOnMountProps> = ({ to = '/' }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectOnMount;
