import {
  FC,
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import steps, {
  BrokerageSignUpWorkflowStep,
} from 'constants/brokerageSignUpWorkflow';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import brokerageSignUpContextInitalState from 'constants/brokerageSignUpContextInitalState';

export interface BrokerageSignUpContextProps {
  currentStep: BrokerageSignUpWorkflowStep;
  currentStepIndex: number;
  nextStep: BrokerageSignUpWorkflowStep;
  stepsSum: number;
  nextButtonEnabled: boolean;
  lastStep: boolean;
}

const BrokerageSignUpContext = createContext<BrokerageSignUpContextProps>({
  currentStep: steps[0],
  currentStepIndex: 0,
  nextStep: steps[1],
  stepsSum: steps.length,
  nextButtonEnabled: false,
  lastStep: false,
});

interface BrokerageSignUpProviderProps {
  children: ReactNode;
  initialState?: BrokerageSignUpContextProps;
}

const BrokerageSignUpProvider: FC<BrokerageSignUpProviderProps> = ({
  children,
  initialState,
}) => {
  const nextButtonEnabled = useAppSelector(
    (state) => state.brokerageSignUp.nextButtonEnabled,
  );

  const [state, setState] = useState<BrokerageSignUpContextProps>(
    initialState || brokerageSignUpContextInitalState,
  );

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const currentIndex = steps.findIndex((step) => step.path === pathname);
    const lastStepIndex = steps.length - 1;

    setState({
      currentStep: steps[currentIndex],
      currentStepIndex: currentIndex,
      nextStep:
        steps[currentIndex === lastStepIndex ? currentIndex : currentIndex + 1],
      stepsSum: steps.length,
      nextButtonEnabled,
      lastStep: currentIndex === lastStepIndex,
    });
  }, [location, nextButtonEnabled]);

  return (
    <BrokerageSignUpContext.Provider value={state}>
      {children}
    </BrokerageSignUpContext.Provider>
  );
};

export default BrokerageSignUpProvider;

export const useBrokerageSignUpContext = () =>
  useContext(BrokerageSignUpContext);
