import { FC } from 'react';
import PageTitle from 'components/page/PageTitle';
import LayoutContainer from 'components/layout/LayoutContainer';
import Header from 'components/layout/Header';
import LayoutContentWrapper from 'components/layout/LayoutContentWrapper';
import { Typography } from '@mui/material';

const ErrorPage: FC = () => (
  <LayoutContainer>
    <Header />
    <LayoutContentWrapper>
      <PageTitle title="Something went wrong" />
      <Typography variant="body1">
        It looks like an error has occurred. Please try again later or contact
        customer service. Sorry for the inconvenience.
      </Typography>
    </LayoutContentWrapper>
  </LayoutContainer>
);

export default ErrorPage;
