import { FC, useEffect, memo, useRef, useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import fetchUserByIdThunk from 'store/slices/authSlice/authThunks';

const AuthProvider: FC = () => {
  const dispatch = useAppDispatch();

  const getParams = useCallback(() => {
    const url = window.location.href;

    if (!url.includes('?')) {
      return {};
    }

    const rawParams = url.split('?')[1];
    const splittedParams = rawParams.split('&');

    const params = splittedParams.reduce<Record<string, string>>(
      (acc, curr) => {
        const splittedParam = curr.split('=');
        return {
          ...acc,
          [splittedParam[0]]: splittedParam[1],
        };
      },
      {},
    );

    return params;
  }, []);

  // In development build, because of the strict mode it gets mounted twice, not happening in production mode
  const secondLoad = useRef<boolean>(false);
  useEffect(() => {
    if (secondLoad.current) {
      return;
    }
    if (!secondLoad.current) {
      secondLoad.current = true;
    }

    const params = getParams();
    const userId = params?.userId || '';
    const token = params?.token || '';

    dispatch(
      fetchUserByIdThunk({
        userId,
        token,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default memo(AuthProvider);
