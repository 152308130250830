import { FC, Suspense, useEffect } from 'react';
import ReactLazyPreload from 'helpers/navigation/reactLazyPreload';
import { Routes, Route } from 'react-router-dom';
import AppLayout from 'layouts/AppLayout';
import NotFoundPage from 'pages/Common/NotFoundPage/NotFoundPage';
import AppRoutes from './appRoutes';
import RedirectOnMount from './RedirectOnMount';

const HomePage = ReactLazyPreload(() => import('pages/App/HomePage/HomePage'));

const AppRouter: FC = () => {
  useEffect(() => {
    HomePage.preload();
  }, []);

  return (
    <Suspense fallback={<AppLayout />}>
      <RedirectOnMount />
      <Routes>
        <Route path={AppRoutes.Home} element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
