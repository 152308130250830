import { FC } from 'react';
import { Box } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';
import { useBrokerageSignUpContext } from 'providers/BrokerageSignUpProvider';

const StepIndicator: FC = () => {
  const { stepsSum, currentStepIndex } = useBrokerageSignUpContext();

  const { createSxStyles, mergeSxStyles } = useThemeContext();
  const { conatiner, stepIndicator, active } = createSxStyles({
    conatiner: {
      display: 'flex',
      mt: 2,
      mb: 2,
    },
    stepIndicator: {
      width: 8,
      height: 8,
      mr: 1,
      borderRadius: 4,
      backgroundColor: 'action.disabled',
    },
    active: {
      backgroundColor: 'primary.main',
    },
  });

  return (
    <Box sx={conatiner}>
      {Array.from(Array(stepsSum).keys()).map((key) => (
        <Box
          sx={mergeSxStyles([
            stepIndicator,
            key <= currentStepIndex ? active : {},
          ])}
          key={key}
        />
      ))}
    </Box>
  );
};

export default StepIndicator;
