import { FC } from 'react';
import { Button, SxProps, Theme } from '@mui/material';

interface SubmitButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const SubmitButton: FC<SubmitButtonProps> = ({
  title,
  onClick,
  disabled = false,
  sx = {},
}) => (
  <Button variant="contained" onClick={onClick} disabled={disabled} sx={sx}>
    {title}
  </Button>
);

export default SubmitButton;
