import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import authInitalState, { AuthState } from './authInitialState';

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitalState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authenticated: action.payload,
    }),
    setAuthLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authLoading: action.payload,
    }),
    setAuthError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authError: action.payload,
    }),
    setHasAccount: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasAccount: action.payload,
    }),
    updateAuthState: (state, action: PayloadAction<Partial<AuthState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  setAuthStatus: setAuthStatusAction, // used for development purposes
  setAuthLoading: setAuthLoadingAction,
  setAuthError: setAuthErrorAction,
  setHasAccount: setHasAccountAction,
  updateAuthState: updateAuthStateAction,
} = authSlice.actions;

export default authSlice.reducer;
