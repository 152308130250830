import {
  IdentityInterface,
  ContactInterface,
  DisclosuresInterface,
} from 'types/brokerageService/accounts';
import { TaxIdType } from 'types/brokerageService/enums';
import {
  BrokerageSignUpCompletedSteps,
  BrokerageSignUpState,
} from 'types/globalStoreTypes/brokerageSignUpTypes';

const contactInitialState: ContactInterface = {
  email_address: '',
  phone_number: '',
  street_address: [],
  unit: null,
  city: '',
  state: null,
  postal_code: null,
  country: 'USA',
};

const identityInitialState: IdentityInterface = {
  given_name: '',
  middle_name: null,
  family_name: '',
  date_of_birth: '',
  tax_id: null,
  tax_id_type: TaxIdType.USA_SSN,
  country_of_citizenship: null,
  country_of_birth: null,
  country_of_tax_residence: 'USA',
  visa_type: null,
  visa_expiration_date: null,
  date_of_departure_from_usa: null,
  permanent_resident: null,
  funding_source: null,
  annual_income_min: null,
  annual_income_max: null,
  liquid_net_worth_min: null,
  liquid_net_worth_max: null,
  total_net_worth_min: null,
  total_net_worth_max: null,
};

const disclosuresIntitialState: DisclosuresInterface = {
  is_control_person: null,
  is_affiliated_exchange_or_finra: null,
  is_politically_exposed: null,
  immediate_family_exposed: null,
  context: null,
  employment_status: null,
  employer_name: null,
  employer_address: null,
  employment_position: null,
};

const completedStepsInitialState: BrokerageSignUpCompletedSteps = {
  name: false,
  contact: false,
  taxInfo: false,
  address: false,
  financialInfo: false,
  affiliations: false,
  agreements: false,
};

const brokerageSignUpInitialState: BrokerageSignUpState = {
  signUpData: {
    contact: contactInitialState,
    identity: identityInitialState,
    disclosures: disclosuresIntitialState,
    agreements: [],
    documents: null,
    trusted_contact: null,
  },
  nextButtonEnabled: false,
  completedSteps: completedStepsInitialState,
  accountCreationError: false,
};

export default brokerageSignUpInitialState;
