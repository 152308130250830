import { FC } from 'react';
import bitsLogo from 'assets/bitsLogo.png';
import { Box } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';

const BitsLogo: FC = () => {
  const { createSxStyles } = useThemeContext();
  const { container } = createSxStyles({
    container: {
      lineHeight: 1,
    },
  });

  return (
    <Box sx={container}>
      <img src={bitsLogo} width={32} height={32} alt="bits of stock" />
    </Box>
  );
};

export default BitsLogo;
