import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import generalInitialState from './generalInitialState';

const generalSlice = createSlice({
  name: 'general',
  initialState: generalInitialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authLoading: action.payload,
    }),
  },
});

export const { setLoading: setLoadingAction } = generalSlice.actions;

export default generalSlice.reducer;
