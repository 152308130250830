import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';

interface LayoutContentWrapperProps {
  children: ReactNode;
}

const LayoutContentWrapper: FC<LayoutContentWrapperProps> = ({ children }) => {
  const { createSxStyles } = useThemeContext();
  const { content } = createSxStyles({
    content: {
      flex: 1,
      mb: 2,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
  });

  return <Box sx={content}>{children}</Box>;
};

export default LayoutContentWrapper;
