import { PayloadAction } from '@reduxjs/toolkit';
import {
  BrokerageSignUpState,
  UpdateBrokerageSignUpActionPayload,
} from 'types/globalStoreTypes/brokerageSignUpTypes';

export const mergeDocumentsState = (
  state: BrokerageSignUpState,
  action: PayloadAction<UpdateBrokerageSignUpActionPayload>,
) => {
  if (
    Array.isArray(state.signUpData.documents) &&
    Array.isArray(action.payload.documents)
  ) {
    return [...state.signUpData.documents, ...action.payload.documents];
  }

  if (
    Array.isArray(state.signUpData.documents) &&
    !Array.isArray(action.payload.documents)
  ) {
    return state.signUpData.documents;
  }

  if (
    !Array.isArray(state.signUpData.documents) &&
    Array.isArray(action.payload.documents)
  ) {
    return action.payload.documents;
  }

  return state.signUpData.documents;
};

export const mergeTrustedContactState = (
  state: BrokerageSignUpState,
  action: PayloadAction<UpdateBrokerageSignUpActionPayload>,
) => {
  if (state.signUpData.trusted_contact && action.payload.trusted_contact) {
    return {
      ...state.signUpData.trusted_contact,
      ...action.payload.trusted_contact,
    };
  }

  if (state.signUpData.trusted_contact && !action.payload.trusted_contact) {
    return state.signUpData.trusted_contact;
  }

  if (!state.signUpData.trusted_contact && action.payload.trusted_contact) {
    return action.payload.trusted_contact;
  }

  return state.signUpData.trusted_contact;
};
