import { FC } from 'react';
import PageTitle from 'components/page/PageTitle';
import { Typography } from '@mui/material';
import Header from 'components/layout/Header';
import LayoutContainer from 'components/layout/LayoutContainer';
import LayoutContentWrapper from 'components/layout/LayoutContentWrapper';

const NotFoundPage: FC = () => (
  <LayoutContainer>
    <Header />
    <LayoutContentWrapper>
      <PageTitle title="Page not found" />
      <Typography variant="body1">
        It looks like the requested page does not exist. Sorry for the
        inconvenience.
      </Typography>
    </LayoutContentWrapper>
  </LayoutContainer>
);

export default NotFoundPage;
