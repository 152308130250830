export enum FundingSource {
  EMPLOYMENT_INCOME = 'employment_income',
  INVESTMENTS = 'investments',
  INHERITANCE = 'inheritance',
  BUSINESS_INCOME = 'business_income',
  SAVINGS = 'savings',
  FAMILY = 'family',
}

/**
 * Document type used for brokerage ID verification
 */
export enum DocumentType {
  IDENTITY_VERIFICATION = 'identity_verification',
  ADDRESS_VERIFICATION = 'address_verification',
  DATE_OF_BIRTH_VERIFICATION = 'date_of_birth_verification',
  TAX_ID_VERIFICATION = 'tax_id_verification',
  ACCOUNT_APPROVAL_LETTER = 'account_approval_letter',
  LIMITED_TRADING_AUTHORIZATION = 'limited_trading_authorization',
  W8BEN = 'w8ben',
}

export enum DisclosureContextType {
  CONTROLLED_FIRM = 'CONTROLLED_FIRM',
  AFFILIATE_FIRM = 'AFFILIATE_FIRM',
  IMMEDIATE_FAMILY_EXPOSED = 'IMMEDIATE_FAMILY_EXPOSED',
}

/**
 * Lifecycle brokerage account status
 */
export enum AccountStatus {
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED',
  ACCOUNT_UPDATED = 'ACCOUNT_UPDATED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  ACTIVE = 'ACTIVE',
  AML_REVIEW = 'AML_REVIEW',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  APPROVED = 'APPROVED',
  DISABLED = 'DISABLED',
  DISABLE_PENDING = 'DISABLE_PENDING',
  EDITED = 'EDITED',
  INACTIVE = 'INACTIVE',
  KYC_SUBMITTED = 'KYC_SUBMITTED',
  LIMITED = 'LIMITED',
  ONBOARDING = 'ONBOARDING',
  PAPER_ONLY = 'PAPER_ONLY',
  REAPPROVAL_PENDING = 'REAPPROVAL_PENDING',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  SIGNED_UP = 'SIGNED_UP',
  SUBMISSION_FAILED = 'SUBMISSION_FAILED',
  SUBMITTED = 'SUBMITTED',
}

export enum AgreementType {
  MARGIN = 'margin_agreement',
  ACCOUNT = 'account_agreement',
  CUSTOMER = 'customer_agreement',
  CRYPTO = 'crypto_agreement',
}

export enum EmploymentStatus {
  UNEMPLOYED = 'unemployed',
  EMPLOYED = 'employed',
  STUDENT = 'student',
  RETIRED = 'retired',
}

/**
 * The various country specific tax identification numbers
 */
export enum TaxIdType {
  USA_SSN = 'USA_SSN',
  ARG_AR_CUIT = 'ARG_AR_CUIT',
  AUS_TFN = 'AUS_TFN',
  AUS_ABN = 'AUS_ABN',
  BOL_NIT = 'BOL_NIT',
  BRA_CPF = 'BRA_CPF',
  CHL_RUT = 'CHL_RUT',
  COL_NIT = 'COL_NIT',
  CRI_NITE = 'CRI_NITE',
  DEU_TAX_ID = 'DEU_TAX_ID',
  DOM_RNC = 'DOM_RNC',
  ECU_RUC = 'ECU_RUC',
  FRA_SPI = 'FRA_SPI',
  GBR_UTR = 'GBR_UTR',
  GBR_NINO = 'GBR_NINO',
  GTM_NIT = 'GTM_NIT',
  HND_RTN = 'HND_RTN',
  HUN_TIN = 'HUN_TIN',
  IDN_KTP = 'IDN_KTP',
  IND_PAN = 'IND_PAN',
  ISR_TAX_ID = 'ISR_TAX_ID',
  ITA_TAX_ID = 'ITA_TAX_ID',
  JPN_TAX_ID = 'JPN_TAX_ID',
  MEX_RFC = 'MEX_RFC',
  NIC_RUC = 'NIC_RUC',
  NLD_TIN = 'NLD_TIN',
  PAN_RUC = 'PAN_RUC',
  PER_RUC = 'PER_RUC',
  PRY_RUC = 'PRY_RUC',
  SGP_NRIC = 'SGP_NRIC',
  SGP_FIN = 'SGP_FIN',
  SGP_ASGD = 'SGP_ASGD',
  SGP_ITR = 'SGP_ITR',
  SLV_NIT = 'SLV_NIT',
  SWE_TAX_ID = 'SWE_TAX_ID',
  URY_RUT = 'URY_RUT',
  VEN_RIF = 'VEN_RIF',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

/**
 * In addition to the following USA visa categories, we accept any sub visas of the list below.
 * Sub visas must be passed in according to their parent category.
 * Note that United States green card holders are considered permanent residents and should not pass in a visa type.
 */
export enum VisaType {
  B1 = 'B1',
  B2 = 'B2',
  DACA = 'DACA',
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  F1 = 'F1',
  G4 = 'G4',
  H1B = 'H1B',
  J1 = 'J1',
  L1 = 'L1',
  Other = 'Other',
  O1 = 'O1',
  TN1 = 'TN1',
}
