import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from 'providers/ThemeProvider';
import GlobalStore from 'store/store';
import AuthProvider from 'providers/AuthProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import ErrorPage from 'pages/Common/ErrorPage/ErrorPage';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'stylesFixes.css';

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID as string;

ReactGA.initialize(gaMeasurementId, {
  testMode: process.env.NODE_ENV !== 'production',
  gtagUrl: `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <GlobalStore>
        <AuthProvider />
        <ThemeProvider>
          <CssBaseline enableColorScheme />
          <App />
        </ThemeProvider>
      </GlobalStore>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
