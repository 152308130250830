import { FC } from 'react';
import SignUpRouter from 'router/SignUpRouter';
import LoadingPage from 'pages/Common/LoadingPage/LoadingPage';
import ErrorPage from 'pages/Common/ErrorPage/ErrorPage';
import { useAppSelector } from 'store/hooks';
import AppRouter from 'router/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import AnalyticsProvider from 'providers/AnalyticsProvider';

const App: FC = () => {
  const { authLoading, authError, authenticated, hasAccount } = useAppSelector(
    (state) => state.auth,
  );

  if (authLoading) return <LoadingPage />;
  if (authError) return <ErrorPage />;

  return (
    <BrowserRouter>
      <AnalyticsProvider />
      {authenticated && !hasAccount ? <SignUpRouter /> : null}
      {authenticated && hasAccount ? <AppRouter /> : null}
      {!authenticated && !hasAccount ? <ErrorPage /> : null}
    </BrowserRouter>
  );
};

export default App;
