import { FC } from 'react';
import BackButton from 'components/buttons/BackButton';
import NextButton from 'components/buttons/SubmitButton';
import { useThemeContext } from 'providers/ThemeProvider';
import { Box } from '@mui/material';
import { useBrokerageSignUpContext } from 'providers/BrokerageSignUpProvider';
import { useNavigate } from 'react-router-dom';
import createBrokerageUserThunk from 'store/slices/brokerageSignUpSlice/brokerageSignUpThunks';
import { useAppDispatch } from 'store/hooks';

const Footer: FC = () => {
  const { createSxStyles } = useThemeContext();
  const { container, nextButton } = createSxStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    nextButton: {
      ml: 2,
    },
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { nextStep, currentStepIndex, stepsSum, nextButtonEnabled, lastStep } =
    useBrokerageSignUpContext();

  const handleNextClick = () => {
    if (!nextButtonEnabled) {
      return;
    }

    if (lastStep) {
      dispatch(createBrokerageUserThunk());
      return;
    }

    navigate(nextStep.path);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box sx={container}>
      {currentStepIndex !== 0 && <BackButton onClick={handleBackClick} />}
      <NextButton
        title={currentStepIndex === stepsSum - 1 ? 'Submit' : 'Next'}
        onClick={handleNextClick}
        sx={nextButton}
        disabled={!nextButtonEnabled}
      />
    </Box>
  );
};

export default Footer;
