enum BrokerageSignUpRoutes {
  Name = '/',
  Contact = '/contact-information',
  TaxInfo = '/tax-information',
  Address = '/address',
  FinancialInfo = '/financial-information',
  Affiliations = '/affiliations',
  Agreements = '/agreements',
}

export default BrokerageSignUpRoutes;
