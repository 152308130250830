import { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authreducer from 'store/slices/authSlice/authSlice';
import brokerageSignUpReducer from 'store/slices/brokerageSignUpSlice/brokerageSignUpSlice';
import generalReducer from 'store/slices/general/generalSlice';

const enableDevTools = process.env.NODE_ENV === 'development';

export const store = configureStore({
  reducer: {
    auth: authreducer,
    brokerageSignUp: brokerageSignUpReducer,
    general: generalReducer,
  },
  devTools: enableDevTools,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface GlobalStoreProps {
  children: ReactNode;
}

const GlobalStore: FC<GlobalStoreProps> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export default GlobalStore;
