import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { UpdateBrokerageSignUpActionPayload } from 'types/globalStoreTypes/brokerageSignUpTypes';
import { updateBrokerageSignUpDataAction } from 'store/slices/brokerageSignUpSlice/brokerageSignUpSlice';
import debounce from 'lodash.debounce';

const useSaveBrokerageSignUpData = () => {
  const dispatch = useAppDispatch();

  const save = (data: UpdateBrokerageSignUpActionPayload) => {
    dispatch(updateBrokerageSignUpDataAction(data));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useMemo(() => debounce(save, 300), []);

  // useEffect(() => () => debouncedSave.cancel(), []);

  return { saveBrokerageSignUpData: debouncedSave };
};

// eslint-disable-next-line import/prefer-default-export
export { useSaveBrokerageSignUpData };
