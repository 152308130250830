import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BrokerageSignUpCompletedSteps,
  UpdateBrokerageSignUpActionPayload,
} from 'types/globalStoreTypes/brokerageSignUpTypes';
import brokerageSignUpInitialState from './brokerageSignUpInitialState';
import {
  mergeDocumentsState,
  mergeTrustedContactState,
} from './stateMergeHelpers';

const brokerageSignUpSlice = createSlice({
  name: 'brokerageSignUp',
  initialState: brokerageSignUpInitialState,
  reducers: {
    updateBrokerageSignUpData: (
      state,
      action: PayloadAction<UpdateBrokerageSignUpActionPayload>,
    ) => ({
      ...state,
      signUpData: {
        ...state.signUpData,
        contact: {
          ...state.signUpData.contact,
          ...action.payload.contact,
        },
        identity: {
          ...state.signUpData.identity,
          ...action.payload.identity,
        },
        disclosures: {
          ...state.signUpData.disclosures,
          ...action.payload.disclosures,
        },
        agreements: action.payload.agreements || state.signUpData.agreements,
        documents: mergeDocumentsState(state, action),
        trusted_contact: mergeTrustedContactState(state, action),
      },
    }),
    setNextButtonEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      nextButtonEnabled: action.payload,
    }),
    updateCompletedSteps: (
      state,
      action: PayloadAction<Partial<BrokerageSignUpCompletedSteps>>,
    ) => ({
      ...state,
      completedSteps: {
        ...state.completedSteps,
        ...action.payload,
      },
    }),
    setAccountCreationError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      accountCreationError: action.payload,
    }),
    resetBrokerageSignUpState: () => brokerageSignUpInitialState,
  },
});

export const {
  updateBrokerageSignUpData: updateBrokerageSignUpDataAction,
  setNextButtonEnabled: setNextButtonEnabledAction,
  updateCompletedSteps: updateCompletedStepsAction,
  setAccountCreationError: setAccountCreationErrorAction,
  resetBrokerageSignUpState: resetBrokerageSignUpStateAction,
} = brokerageSignUpSlice.actions;

export default brokerageSignUpSlice.reducer;
