import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';

interface LayoutContainerProps {
  children: ReactNode;
}

const LayoutContainer: FC<LayoutContainerProps> = ({ children }) => {
  const { createSxStyles } = useThemeContext();
  const { wrapper, container } = createSxStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      height: '100vh',
      width: '100%',
      maxWidth: 750,
      px: 4,
      py: 2,
    },
  });

  return (
    <Box sx={wrapper}>
      <Box sx={container}>{children}</Box>
    </Box>
  );
};

export default LayoutContainer;
