import { BrokerageSignUpContextProps } from 'providers/BrokerageSignUpProvider';
import steps from 'constants/brokerageSignUpWorkflow';

const brokerageSignUpContextInitalState: BrokerageSignUpContextProps = {
  currentStep: steps[0],
  currentStepIndex: 0,
  nextStep: steps[1],
  stepsSum: steps.length,
  nextButtonEnabled: false,
  lastStep: false,
};

export default brokerageSignUpContextInitalState;
