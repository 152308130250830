import { apiKey, baseUrl, brokerageAccountUrl, userUrl } from 'apis/urls';
import { AppThunk } from 'store/store';
import { setHasAccountAction } from 'store/slices/authSlice/authSlice';
import { setLoadingAction } from 'store/slices/general/generalSlice';
import {
  addBreadcrumb as sentryAddBreadcrumb,
  captureException as sentryCaptureException,
} from '@sentry/react';
import {
  resetBrokerageSignUpStateAction,
  setAccountCreationErrorAction,
} from './brokerageSignUpSlice';

const createBrokerageUserThunk = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoadingAction(true));

  const {
    brokerageSignUp: { signUpData },
    auth: { token, userId },
  } = getState();

  // todo create a formatting helper
  const ssn = signUpData.identity.tax_id ?? '';
  const formattedSSN = ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  const modifiedSignUpData = {
    ...signUpData,
    identity: {
      ...signUpData.identity,
      tax_id: formattedSSN,
    },
  };

  const headers = {
    Authorization: `TokenEnduser ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json;',
    'Accept-Language': 'en',
    'x-api-key': apiKey,
  };

  try {
    sentryAddBreadcrumb({
      message: 'createBrokerageUserThunk',
      data: {
        token,
        userId,
        url: `${baseUrl}/${userUrl}/${userId}/${brokerageAccountUrl}`,
      },
    });

    const response = await fetch(
      `${baseUrl}/${userUrl}/${userId}/${brokerageAccountUrl}`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify(modifiedSignUpData),
      },
    );

    if (response.status < 200 || response.status > 299) {
      throw Error('Error in create brokerage account call.');
    }

    dispatch(setHasAccountAction(true));
    dispatch(resetBrokerageSignUpStateAction());
  } catch (error) {
    dispatch(setAccountCreationErrorAction(true));
    sentryCaptureException(error, {
      extra: {
        notes: 'Error in createBrokerageUserThunk.',
      },
    });
  }

  dispatch(setLoadingAction(false));
};

export default createBrokerageUserThunk;
