import { FC } from 'react';
import Header from 'components/layout/Header';
import { Outlet } from 'react-router-dom';
import LayoutContainer from 'components/layout/LayoutContainer';
import LayoutContentWrapper from 'components/layout/LayoutContentWrapper';

const AppLayout: FC = () => (
  <LayoutContainer>
    <Header />
    <LayoutContentWrapper>
      <Outlet />
    </LayoutContentWrapper>
  </LayoutContainer>
);

export default AppLayout;
