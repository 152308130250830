import { FC, Suspense, useEffect } from 'react';
import BrokerageSignUpLayout from 'layouts/BrokerageSignUpLayout';
import ReactLazyPreload from 'helpers/navigation/reactLazyPreload';
import { Routes, Route } from 'react-router-dom';
import BrokerageSignUpRoutes from 'router/brokerageSignUpRoutes';
import { useAppSelector } from 'store/hooks';
import ErrorPage from 'pages/Common/ErrorPage/ErrorPage';
import RedirectOnMount from './RedirectOnMount';

const SignUpRouter: FC = () => {
  const NamePage = ReactLazyPreload(
    () => import('pages/SignUp/NamePage/NamePage'),
  );
  const ContactPage = ReactLazyPreload(
    () => import('pages/SignUp/ContactPage/ContactPage'),
  );
  const AddressPage = ReactLazyPreload(
    () => import('pages/SignUp/AddressPage/AddressPage'),
  );
  const TaxInfoPage = ReactLazyPreload(
    () => import('pages/SignUp/TaxInfoPage/TaxInfoPage'),
  );
  const FinancialInfoPage = ReactLazyPreload(
    () => import('pages/SignUp/FinancialInfoPage/FinancialInfoPage'),
  );
  const AffiliationsPage = ReactLazyPreload(
    () => import('pages/SignUp/AffiliationsPage/AffiliationsPage'),
  );
  const AgreementsPage = ReactLazyPreload(
    () => import('pages/SignUp/AgreementsPage/AgreementsPage'),
  );
  const NotFoundPage = ReactLazyPreload(
    () => import('pages/Common/NotFoundPage/NotFoundPage'),
  );

  const accountCreationError = useAppSelector(
    (state) => state.brokerageSignUp.accountCreationError,
  );

  useEffect(() => {
    NamePage.preload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (accountCreationError) return <ErrorPage />;

  return (
    <Suspense fallback={<BrokerageSignUpLayout />}>
      <RedirectOnMount />
      <Routes>
        <Route
          path={BrokerageSignUpRoutes.Name}
          element={<BrokerageSignUpLayout />}
        >
          <Route index element={<NamePage />} />
          <Route
            path={BrokerageSignUpRoutes.Contact}
            element={<ContactPage />}
          />
          <Route
            path={BrokerageSignUpRoutes.TaxInfo}
            element={<TaxInfoPage />}
          />
          <Route
            path={BrokerageSignUpRoutes.Address}
            element={<AddressPage />}
          />
          <Route
            path={BrokerageSignUpRoutes.FinancialInfo}
            element={<FinancialInfoPage />}
          />
          <Route
            path={BrokerageSignUpRoutes.Affiliations}
            element={<AffiliationsPage />}
          />
          <Route
            path={BrokerageSignUpRoutes.Agreements}
            element={<AgreementsPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default SignUpRouter;
