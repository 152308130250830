import { BrokerageSignUpWorkflowStep } from 'constants/brokerageSignUpWorkflow';

interface SignUpAnimationHelperParams {
  steps: BrokerageSignUpWorkflowStep[];
  currRoute: string;
  prevRoute: string | null;
}

const signUpAnimationHelper = ({
  steps,
  currRoute,
  prevRoute,
}: SignUpAnimationHelperParams) => {
  const stepOrder = steps.map((step) => step.path);

  if (!prevRoute) {
    return {
      initial: { translateX: 0, opacity: 0 },
      animate: { translateX: 0, opacity: 1 },
      exit: { translateX: '-100%' },
    };
  }

  const currIndex = stepOrder.findIndex((stepName) => stepName === currRoute);
  const prevIndex = stepOrder.findIndex((stepName) => stepName === prevRoute);

  if (currIndex < prevIndex) {
    return {
      initial: { translateX: '-100%' },
      animate: { translateX: 0 },
      exit: { translateX: '100%' },
    };
  }

  return {
    initial: { translateX: '100%' },
    animate: { translateX: 0 },
    exit: { translateX: '-100%' },
  };
};

export default signUpAnimationHelper;
