import BrokerageSignUpRoutes from 'router/brokerageSignUpRoutes';

export type StepNames =
  | 'name'
  | 'contact'
  | 'taxInfo'
  | 'address'
  | 'financialInfo'
  | 'affiliations'
  | 'agreements';

export type BrokerageSignUpWorkflowStep = {
  name: StepNames;
  path: BrokerageSignUpRoutes;
};

const setps: BrokerageSignUpWorkflowStep[] = [
  {
    name: 'name',
    path: BrokerageSignUpRoutes.Name,
  },
  {
    name: 'contact',
    path: BrokerageSignUpRoutes.Contact,
  },
  {
    name: 'taxInfo',
    path: BrokerageSignUpRoutes.TaxInfo,
  },
  {
    name: 'address',
    path: BrokerageSignUpRoutes.Address,
  },
  {
    name: 'financialInfo',
    path: BrokerageSignUpRoutes.FinancialInfo,
  },
  {
    name: 'affiliations',
    path: BrokerageSignUpRoutes.Affiliations,
  },
  {
    name: 'agreements',
    path: BrokerageSignUpRoutes.Agreements,
  },
];

export default setps;
