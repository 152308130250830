import { FC, useLayoutEffect, useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import BrokerageSignUpProvider from 'providers/BrokerageSignUpProvider';
import Header from 'components/layout/Header';
import StepIndicator from 'components/layout/StepIndicator';
import Footer from 'components/layout/Footer';
import BrokerageSignUpFormDataProvider from 'providers/BrokerageSignUpFormDataProvider';
import { AnimatePresence, motion } from 'framer-motion';
import steps from 'constants/brokerageSignUpWorkflow';
import signUpAnimationHelper from 'helpers/navigation/signUpAnimationHelper';
import LayoutContainer from 'components/layout/LayoutContainer';
import LayoutContentWrapper from 'components/layout/LayoutContentWrapper';

const BrokerageSignUpLayout: FC = () => {
  const location = useLocation();

  const previousRoute = useRef<string | null>(null);
  useLayoutEffect(() => {
    previousRoute.current = location.pathname;
  }, [location]);

  const navigationAnim = useMemo(
    () =>
      signUpAnimationHelper({
        steps,
        currRoute: location.pathname,
        prevRoute: previousRoute.current,
      }),
    [location.pathname],
  );

  return (
    <LayoutContainer>
      <Header />
      <AnimatePresence>
        <BrokerageSignUpProvider>
          <StepIndicator />
          <LayoutContentWrapper>
            <BrokerageSignUpFormDataProvider>
              <motion.div
                key={location.pathname}
                initial={navigationAnim.initial}
                animate={navigationAnim.animate}
                exit={navigationAnim.exit}
                transition={{ ease: 'easeInOut', duration: 0.3 }}
              >
                <Outlet />
              </motion.div>
            </BrokerageSignUpFormDataProvider>
          </LayoutContentWrapper>
          <Footer />
        </BrokerageSignUpProvider>
      </AnimatePresence>
    </LayoutContainer>
  );
};

export default BrokerageSignUpLayout;
