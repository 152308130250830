export type AuthState = {
  authenticated: boolean;
  authLoading: boolean;
  authError: boolean;
  hasAccount: boolean;
  token: string;
  userId: string;
};

const authInitialState: AuthState = {
  authenticated: false,
  authLoading: true,
  authError: false,
  hasAccount: false,
  token: '',
  userId: '',
};

export default authInitialState;
