import { Box, Typography } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';
import { FC } from 'react';
import BitsLogo from './BitsLogo';

const Header: FC = () => {
  const { createSxStyles } = useThemeContext();
  const { container, title } = createSxStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      ml: 2,
    },
  });

  return (
    <Box sx={container}>
      <BitsLogo />
      <Typography variant="h6" color="text.secondary" sx={title}>
        Brokerage Creation
      </Typography>
    </Box>
  );
};

export default Header;
