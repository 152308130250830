import { baseUrl, userUrl, brokerageAccountUrl, apiKey } from 'apis/urls';
import { AppThunk } from 'store/store';
import {
  addBreadcrumb as sentryAddBreadcrumb,
  captureException as sentryCaptureException,
} from '@sentry/react';
import {
  setAuthErrorAction,
  setAuthLoadingAction,
  updateAuthStateAction,
} from './authSlice';

interface FetchUserByIThunkParams {
  userId: string;
  token: string;
}

const fetchUserByIdThunk =
  ({ userId, token }: FetchUserByIThunkParams): AppThunk =>
  async (dispatch) => {
    if (!userId || !token) {
      dispatch(setAuthLoadingAction(false));
      dispatch(setAuthErrorAction(true));
      return;
    }

    dispatch(setAuthLoadingAction(true));
    const headers = {
      Authorization: `TokenEnduser ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json;',
      'Accept-Language': 'en',
      'x-api-key': apiKey,
    };

    try {
      sentryAddBreadcrumb({
        message: 'fetchUserByIdThunk',
        data: {
          token,
          userId,
          url: `${baseUrl}/${userUrl}/${userId}/${brokerageAccountUrl}`,
        },
      });

      const response = await fetch(
        `${baseUrl}/${userUrl}/${userId}/${brokerageAccountUrl}`,
        {
          headers,
        },
      );

      const paresedResponse = await response.json();

      if (
        paresedResponse.status === 404 &&
        paresedResponse?.detail &&
        paresedResponse?.detail !== 'bits_user_id has no brokerage account!'
      ) {
        throw Error('Error in get brokerage account call.');
      }

      if (
        (response.status < 200 || response.status > 299) &&
        response.status !== 404
      ) {
        throw Error('Error in get brokerage account call.');
      }

      dispatch(
        updateAuthStateAction({
          authenticated: true,
          hasAccount: response.status === 200,
          token,
          userId,
        }),
      );
    } catch (error) {
      dispatch(setAuthErrorAction(true));
      sentryCaptureException(error, {
        extra: {
          notes: 'Error in fetchUserByIdThunk.',
        },
      });
    }

    dispatch(setAuthLoadingAction(false));
  };

export default fetchUserByIdThunk;
