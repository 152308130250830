import { useSaveBrokerageSignUpData } from 'hooks/useSaveBrokerageSignUpData';
import {
  FC,
  createContext,
  useContext,
  useReducer,
  Dispatch,
  ReactNode,
  useMemo,
  useEffect,
} from 'react';
import brokerageSignUpInitialState from 'store/slices/brokerageSignUpSlice/brokerageSignUpInitialState';
import {
  AgreementInterface,
  ContactInterface,
  DisclosuresInterface,
  IdentityInterface,
} from 'types/brokerageService/accounts';
import { BrokerageSignUpStateData } from 'types/globalStoreTypes/brokerageSignUpTypes';

const signUpInitialData = brokerageSignUpInitialState.signUpData;

const UPDATE_IDENTITY_STATE = 'UPDATE_IDENTITY_STATE';
const UPDATE_CONTACT_STATE = 'UPDATE_CONTACT_STATE';
const UPDATE_DISCLOSURES_STATE = 'UPDATE_DISCLOSURES_STATE';
const UPDATE_AGREEMENTS_STATE = 'UPDATE_AGREEMENTS_STATE';
type ActionTypes =
  | {
      type: typeof UPDATE_IDENTITY_STATE;
      payload: Partial<IdentityInterface>;
    }
  | {
      type: typeof UPDATE_CONTACT_STATE;
      payload: Partial<ContactInterface>;
    }
  | {
      type: typeof UPDATE_DISCLOSURES_STATE;
      payload: Partial<DisclosuresInterface>;
    }
  | {
      type: typeof UPDATE_AGREEMENTS_STATE;
      payload: AgreementInterface[];
    };

const reducer = (state: BrokerageSignUpStateData, action: ActionTypes) => {
  switch (action.type) {
    case 'UPDATE_IDENTITY_STATE':
      return {
        ...state,
        identity: {
          ...state.identity,
          ...action.payload,
        },
      };
    case 'UPDATE_CONTACT_STATE':
      return {
        ...state,
        contact: {
          ...state.contact,
          ...action.payload,
        },
      };
    case 'UPDATE_DISCLOSURES_STATE':
      return {
        ...state,
        disclosures: {
          ...state.disclosures,
          ...action.payload,
        },
      };
    case UPDATE_AGREEMENTS_STATE:
      return {
        ...state,
        agreements: action.payload,
      };
    default:
      return state;
  }
};

interface BrokerageSignUpFormDataContextProps {
  state: BrokerageSignUpStateData;
  dispatch: Dispatch<ActionTypes>;
}

const BrokerageSignUpFormDataContext =
  createContext<BrokerageSignUpFormDataContextProps>({
    state: signUpInitialData,
    dispatch: () => {
      throw new Error('No BrokerageSignUpFormDataContext available.');
    },
  });

interface BrokerageSignUpFormDataProviderProps {
  children: ReactNode;
}
const BrokerageSignUpFormDataProvider: FC<
  BrokerageSignUpFormDataProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, signUpInitialData);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  const { saveBrokerageSignUpData } = useSaveBrokerageSignUpData();

  // todo optimise inital load
  useEffect(() => {
    saveBrokerageSignUpData(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, saveBrokerageSignUpData]);

  return (
    <BrokerageSignUpFormDataContext.Provider value={value}>
      {children}
    </BrokerageSignUpFormDataContext.Provider>
  );
};

export default BrokerageSignUpFormDataProvider;

export const useBrokerageSignUpFormDataProvider = () =>
  useContext(BrokerageSignUpFormDataContext);
