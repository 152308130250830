import { FC } from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import { useThemeContext } from 'providers/ThemeProvider';

interface BackButtonProps {
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const BackButton: FC<BackButtonProps> = ({
  onClick,
  disabled = false,
  sx = {},
}) => {
  const { createSxStyles, mergeSxStyles } = useThemeContext();
  const { button } = createSxStyles({
    button: {
      backgroundColor: 'grey.300',
      color: 'grey.800',
      '&:hover': {
        backgroundColor: 'grey.400',
      },
    },
  });

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={mergeSxStyles([button, sx])}
    >
      Back
    </Button>
  );
};

export default BackButton;
